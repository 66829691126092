import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

export const getFirstValue = (value) => {
  if (value instanceof Date) {
    return value
  }
  if (!Number.isNaN(value)) {
    return new Date(value)
  }

  throw new Error(`Received invalid Date value: ${value}`)
}

export const isValidDateTimeOrNull = dateOrTime => !dateOrTime || !Number.isNaN(dateOrTime.getTime())

export class UTCUtils extends DateFnsUtils {
  format(value, formatString) {
    switch (formatString) {
      case 'yyyy-MM-dd':
        return moment(value).format('YYYY-MM-DD')

      case 'yyyy-MM-dd HH:mm':
        return moment(value).format('YYYY-MM-DD HH:mm')

      case 'd':
        return moment(value).format('DD')

      case 'EEEEEE':
        return moment(value).format('ddd')

      case 'MMMM yyyy':
        return moment(value).format('MMMM YYYY')

      default:
        return moment(value).format(formatString)
    }
  }
}

export const mainStyles = (theme) => {
  const {
    blue3bis: backgroundColor,
    bluewriting: focusBorderColor,
    white: labelColor,
    red: errorBorderColor
  } = theme.palette.common

  const { disabled: disabledLabelColor, disabledBackground } = theme.palette.action

  return {
    label: {
      fontSize: '1rem',
      lineHeight: '1rem',
      color: labelColor,
      top: -8,
      left: 10,
      transform: 'translate(0, 20px) scale(1)',
      '&.MuiFormLabel-root.Mui-focused': {
        color: labelColor
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(-3px, 11px) scale(0.7)'
      }
    },
    inputAdornment: {
      '& .MuiIconButton-root': {
        color: labelColor,
        padding: 7
      },
    },
    input: {
      fontSize: '1rem',
      lineHeight: '1rem',
      color: labelColor,
      'label + &.MuiInput-formControl': {
        marginTop: 8
      },
      '& > .MuiInput-input': {
        padding: '4px 0 7px',
        marginBottom: 'unset'
      },
      '& > .MuiInputAdornment-positionEnd': {
        marginTop: -12
      }
    },
    dateAndTimePicker: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: 2,
      padding: '3px 0 0px 7px',
      backgroundColor,
      color: labelColor,
      maxHeight: 36,
      height: 36,
    },
    disabled: {
      backgroundColor: disabledBackground,
      color: disabledLabelColor,
      '& .MuiFormLabel-root.Mui-disabled, & input, & .MuiIconButton-root': {
        color: disabledLabelColor
      },
      '& > .MuiInputBase-root, & > .MuiInputBase-root > .MuiInputBase-input': {
        backgroundColor: 'transparent', // prevents the label from getting hidden when the component is disabled
      }
    },
    noLabel: {
      padding: '2px 0 2px 6px',
      maxHeight: 32,
      height: 32,
      '& .MuiInput-input': {
        paddingTop: 6
      },
      '& .MuiInputAdornment-positionEnd': {
        marginTop: 0,
        '& > .MuiIconButton-root': {
          padding: 5
        }
      }
    },
    error: {
      borderStyle: 'solid',
      borderColor: errorBorderColor
    },
    focused: {
      borderColor: focusBorderColor
    },
    helper: {
      color: labelColor
    },
  }
}

export const keyboardOnlyStyles = () => ({
  label: {
    display: 'none',
  },
  inputAdornment: {
    display: 'none'
  },
  input: {
    height: 32,
    padding: '0 6px',
    '&& > .MuiInput-input': {
      paddingTop: 7,
    },
  },
  dateAndTimePicker: {
    height: 32,
    padding: 0,
  },
  noLabel: {},
  disabled: {},
  helper: {},
  input: {}
})
