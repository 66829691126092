import { withStyles } from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

export const ATFMRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    '&:hover': {
      opacity: 0.25,
      backgroundColor: theme.palette.common.bluewriting,
      color: theme.palette.common.blue
    },
    'input:disabled ~ &': {
      color: theme.palette.common.greyDisable
    }
  }
}))(Radio)

export const ATFMFormControlLabel = withStyles((theme) => ({
  label: {
    color: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.common.greyDisable
    }
  }
}))(MuiFormControlLabel)

export const ATFMRadioGroup = withStyles((theme) => ({}))(RadioGroup)
