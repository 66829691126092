import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'

const mainStyles = (theme) => ({
  root: {},
  inputRoot: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue3bis,
    borderRadius: 2
  },
  focused: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue3bis,
    borderRadius: 2,
    border: `1px solid ${theme.palette.common.bluewriting}`
  },
  popupIndicator: {
    color: theme.palette.common.white
  },
  paper: {
    backgroundColor: theme.palette.common.blue3bis,
    margin: 0
  },
  noOptions: {
    color: theme.palette.common.white
  },
  option: {
    color: theme.palette.common.white,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.common.blue4,
      borderColor: 'transparent'
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.common.bluewriting,
      borderColor: 'transparent'
    }
  },
  labelStyle: {
    color: theme.palette.common.white,
    '&.Mui-focused': {
      color: theme.palette.common.white
    }
  },
  inputStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.blue3bis
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.common.grey3
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.grey3
    },
    borderRadius: 2
  }
})

const AtfmComboBox = ({ classes, ...props }) => {
  const { inputStyle, labelStyle, ...comboStyle } = classes
  const { label, required, ...comboProps } = props

  return (
    <Autocomplete
      {...comboProps}
      disableClearable
      size='small'
      classes={comboStyle}
      renderInput={(params) => {
        return (
          <MuiTextField
            {...params}
            required={required !== undefined}
            className={inputStyle}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: {
                root: labelStyle
              }
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
            label={label}
            variant='filled'
          />
        )
      }}
    />
  )
}

const PrimaryComboBox = withStyles(mainStyles)(AtfmComboBox)

const SecondaryComboBox = withStyles((theme) => {
  const { inputRoot, focused, paper, inputStyle, ...other } = mainStyles(theme)
  return {
    ...other,
    inputRoot: {
      ...inputRoot,
      backgroundColor: theme.palette.common.blue2
    },
    focused: {
      ...focused,
      backgroundColor: theme.palette.common.blue2
    },
    paper: {
      ...paper,
      backgroundColor: theme.palette.common.blue2
    },
    inputStyle: {
      ...inputStyle,
      '& .MuiFilledInput-root': {
        backgroundColor: theme.palette.common.blue2
      }
    }
  }
})(AtfmComboBox)

export { PrimaryComboBox, SecondaryComboBox }

export const DefaultComboBox = withStyles((theme) => ({}))(Autocomplete)
