import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'

const mainStyleUp = (theme) => {
  const { blue3bis: borderBottomColor } = theme.palette.common;
  return {
    list: {
      padding: '0px',
      background: 'none',
      marginTop: '-5px',
    },
    paper: {
      background: 'none',
      "&:before": {
        display: 'inline-block',
        content: '""',
        width: '0',
        height: '0',
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottomWidth: '8px',
        borderBottomStyle: 'solid',
        borderBottomColor,
        margin: '0px 14px',
      },
    },
  }
}

const mainStyleLeft = (theme) => {
  const { blue3bis: borderLeftColor } = theme.palette.common;
  return {
    list: {
      padding: '8px',
      background: 'none',
      marginTop: '-77px',
    },
    paper: {
      background: 'none',
      "&:before": {
        display: 'inline-block',
        content: '""',
        width: '0',
        height: '0',
        borderTop: '8px solid transparent',
        borderBottom: '8px solid transparent',
        borderLeftWidth: '8px',
        borderLeftStyle: 'solid',
        borderLeftColor,
        margin: '40px 205px',
      },
    },
  }
}

const mainStyleRight = (theme) => {
  const { blue3bis: borderRightColor } = theme.palette.common;
  return {
    list: {
      padding: '8px',
      background: 'none',
      marginTop: '-45px',
    },
    paper: {
      background: 'none',
      "&:before": {
        display: 'inline-block',
        content: '""',
        width: '0',
        height: '0',
        borderTop: '8px solid transparent',
        borderBottom: '8px solid transparent',
        borderRightWidth: '8px',
        borderRightStyle: 'solid',
        borderRightColor,
        margin: '8px 0px',
      },
    },
  }
}

const itemMainStyle = (theme) => {
  const { blue3bis: backgroundColor, white: color } = theme.palette.common;
  return {
    root: {
      height: '37px',
      width: '198px',
      backgroundColor,
      color,
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: theme.palette.common.blue4,
      },
      fontSize: '14px',
    }
  }
}

const MenuStyledItem=withStyles(itemMainStyle)(MenuItem)

const PrimaryMenuUp = withStyles(mainStyleUp)(Menu)
const PrimaryMenuLeft = withStyles(mainStyleLeft)(Menu)
const PrimaryMenuRight = withStyles(mainStyleRight)(Menu)

export const AtfmMenu = ({ anchorEl, handleOpen, handleMouseDown, id, items, children, anchorOriginVertical,
  anchorOriginHorizontal, transformOriginVertical, transformOriginHorizontal }) => {
  let divider
  let PrimaryMenu
  if(anchorOriginVertical==='top' && anchorOriginHorizontal==='right') {PrimaryMenu = PrimaryMenuRight
  } else if(anchorOriginVertical==='top' && anchorOriginHorizontal==='left') { PrimaryMenu = PrimaryMenuLeft
  } else { PrimaryMenu = PrimaryMenuUp
  }
  return (
    <div id='menu'>
      {children}
      <PrimaryMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
        id={id}
        anchorEl={anchorEl}
        open={handleOpen}
        onMouseDown={handleMouseDown}
        items={items}
      >
        {items.map( (item, index) => {
          divider = (index !== (items.length-1));
          return <MenuStyledItem
            divider={divider}
            onClick={item[1]}
            key={index}
          >
            {item[0]}
          </MenuStyledItem>
        })}
      </PrimaryMenu>
    </div>
  );
}

AtfmMenu.propTypes = {
  handleOpen: PropTypes.bool.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};
