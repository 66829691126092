import React, { useEffect, useRef, useState } from 'react'

import { ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { timezoneToUTC, utcToTimezone } from '@atfm/utils'

import { UTCUtils, mainStyles, keyboardOnlyStyles, getFirstValue, isValidDateTimeOrNull } from './common'

export const datePickerStyles = (theme) => {
  const {
    blue3bis: backgroundColor,
    white: labelColor,
    greyDisable: secondaryLabelColor,
    bluewriting: focusBorderColor,
  } = theme.palette.common

  return {
    ...mainStyles(theme),
    datePickerPopover: {
      '& .MuiPopover-paper': {
        backgroundColor,
        color: labelColor,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: focusBorderColor
      },
      '& .MuiPickersCalendarHeader-iconButton': {
        backgroundColor,
        color: labelColor
      },
      '& .MuiPickersDay-day': {
        color: labelColor,
        '&.MuiPickersDay-dayDisabled': {
          color: secondaryLabelColor
        }
      },
      '& .MuiPickersCalendarHeader-dayLabel, & .MuiPickersDay-day .out-of-month': {
        color: secondaryLabelColor
      }
    },
  }
}

const AtfmDatePicker = ({
  label = 'Pick a date',
  value = null,
  onChange,
  classes,
  noLabel,
  disabled,
  error,
  helperText,
  placeholder,
  invalidDateErrorMessage = 'Invalid date',
  PopoverProps = {},
  ...other
}) => {
  const picker = useRef(null)

  const [valid, setValid] = useState(true);
  const [focused, setFocused] = useState(false)

  const [dateSet, setDate] = useState(value ? utcToTimezone(getFirstValue(value)) : null);

  const handleDateChange = (date) => {
    if (!isValidDateTimeOrNull(date)) {
      setValid(false)
      if (onChange) {
        onChange(date)
      }
      return
    }

    setValid(true)
    if (date) {
      const timezoneDate = date
      setDate(timezoneDate)
      if (onChange) {
        const utcDate = timezoneToUTC(timezoneDate)
        onChange(utcDate)
      }
    } else {
      setDate(date)
      if (onChange) {
        onChange(date)
      }
    }
  }

  useEffect(() => {
    const timezoneDate = value ? utcToTimezone(getFirstValue(value)) : null
    setDate(timezoneDate)
  }, [value])

  const css = [classes.dateAndTimePicker]
  if (noLabel) css.push(classes.noLabel)
  if (disabled) css.push(classes.disabled)
  if (error || !valid) css.push(classes.error)
  if (focused) css.push(classes.focused)

  return (
    <MuiPickersUtilsProvider utils={UTCUtils}>
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <div ref={picker}> {/* needed for the ClickAwayListener to work */}
          <KeyboardDatePicker
            className={css.join(' ')}
            disableToolbar
            autoOk
            error={error || !valid}
            helperText={helperText || (!valid && invalidDateErrorMessage)}
            disabled={disabled}
            variant='inline'
            format='yyyy-MM-dd'
            label={noLabel ? null : label}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
                disabled: classes.disabled
              },
              placeholder,
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false)
            }}
            InputAdornmentProps={{
              classes: {
                root: classes.inputAdornment
              },
              onClick: () => setFocused(!focused)
            }}
            PopoverProps={{
              ...PopoverProps,
              classes: {
                root: classes.datePickerPopover,
                ...PopoverProps.classes
              },
              TransitionProps: {
                onExiting: () => setFocused(false),
                ...PopoverProps.TransitionProps
              },
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
                ...PopoverProps.anchorOrigin
              },
              transformOrigin: {
                horizontal: 7,
                vertical: -4,
                ...PopoverProps.transformOrigin
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.helper
              }
            }}
            value={dateSet}
            onChange={handleDateChange}
            {...other}
          />
        </div>
      </ClickAwayListener>
    </MuiPickersUtilsProvider>
  )
}

const secondaryStyles = theme => ({
  dateAndTimePicker: {
    backgroundColor: theme.palette.common.blue2
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  noLabel: {},
  helper: {},
  input: {}
})

const PrimaryDatePicker = withStyles(datePickerStyles)(AtfmDatePicker)
const SecondaryDatePicker = withStyles(secondaryStyles)(PrimaryDatePicker)

const PrimaryKeyboardOnlyDatePicker = withStyles(keyboardOnlyStyles)(props => <PrimaryDatePicker placeholder="YYYY-MM-DD" {...props} label={null} keyboardIcon={null} />)
const SecondaryKeyboardOnlyDatePicker = withStyles(secondaryStyles)(PrimaryKeyboardOnlyDatePicker)

export { PrimaryDatePicker, SecondaryDatePicker, PrimaryKeyboardOnlyDatePicker, SecondaryKeyboardOnlyDatePicker }
