import moment from 'moment'
import { Time, TimelineTimeWindow } from './constants'

export const timeFormat = (value) =>
  value !== undefined ? moment(parseInt(value, 10)).utc().format('HHmm') : ''

export const addToTimeStamp = (day, value) =>
  moment(value).utc().add(day, 'days').valueOf()
export const dateAndTimeToTimestamp = (date, time) =>
  moment.utc(`${date} ${time}`, 'DD/MM/YYYY HHmm')
export const isValidTimeFormat = (value, format) =>
  moment.utc(value, format, true).isValid()
export const removeDayToTimeStamp = (day, value) =>
  moment(value).utc().subtract(day, 'days').valueOf()
export const retrieveOnlyTime = (value) =>
  moment.duration(value).hours() * 60 + moment.duration(value).minutes()

export const atStartOfDay = (date) =>
  new Date(
    moment(date)
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .valueOf()
  )
export const timezoneToUTC = (date) =>
  new Date(
    date.getTime() + -date.getTimezoneOffset() * Time.MINUTE_IN_MILISECONDS
  )
export const utcToTimezone = (date) =>
  new Date(
    date.getTime() - -date.getTimezoneOffset() * Time.MINUTE_IN_MILISECONDS
  )

export const formatedDayToTimeStamp = (value) =>
  moment(`${value}T00:00:00.000Z`).utc().valueOf()
export const formatedToTimeStamp = (value, currentTime, withColon) =>
  moment(currentTime)
    .utc()
    .hour(value.substr(0, 2))
    .minute(value.substr(withColon ? 3 : 2, 2))
    .second('00')
    .valueOf()

export const nearestPastInterval = (interval, timestamp) =>
  Math.floor((timestamp + 1) / interval) * interval
export const nearestNextInterval = (interval, timestamp) =>
  Math.ceil((timestamp + 1) / interval) * interval
export const nearestPastMinutes = (minutes, someMoment, milliseconds = 0) => {
  const roundedMinutes = Math.floor(someMoment.minute() / minutes) * minutes
  return someMoment
    .clone()
    .minute(roundedMinutes)
    .second(0)
    .milliseconds(milliseconds)
}
export const nearestNextMinutes = (minutes, someMoment, milliseconds = 0) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / minutes) * minutes
  return someMoment
    .clone()
    .minute(roundedMinutes)
    .second(0)
    .milliseconds(milliseconds)
}

export const timestampToDate = (timestamp) =>
  moment.utc(timestamp).format('DD/MM/YYYY')
export const timestampToDateAndTime = (timestamp) =>
  moment.utc(timestamp).format('DD/MM/YYYY HH:mm')
export const timestampToDateInverse = (timestamp) =>
  moment(timestamp).utc().format('YYYY-MM-DD')
export const timestampToDateLong = (timestamp) =>
  moment.utc(timestamp).format('dddd, DD MMM YYYY')
export const timestampToFormat = (timestamp, format) =>
  moment.utc(timestamp).format(format)
export const timestampToHH = (timestamp) => moment.utc(timestamp).format('HH')
export const timestampToHHmm = (timestamp) =>
  moment.utc(timestamp).format('HHmm')
export const timestampToHHmmWithColon = (timestamp) =>
  moment.utc(timestamp).format('HH:mm')
export const timestampToHHmmWithH = (timestamp) =>
  moment.utc(timestamp).format('HH[h]mm')
export const timestampToHHmmss = (timestamp) =>
  moment.utc(timestamp).format('HH:mm:ss')

export const dateToFormat = (date, format) => moment(date).format(format)

export const convertMilissecondsToMinutes = (minute) => minute / 1000 / 60
export const convertMinToMilisseconds = (minute) => minute * 60 * 1000
export const convertHHmmWithColonToHHmm = (timestamp) => {
  if (isValidTimeFormat(timestamp, 'HH:mm')) {
    if (timestamp.includes(':')) {
      return timestamp.replace(':', '')
    }
  }
  return timestamp
}
export const convertHHmmToHHmmWithColon = (timestamp) => {
  if (isValidTimeFormat(timestamp, 'HHmm')) {
    if (!timestamp.includes(':')) {
      return `${timestamp.slice(0, 2)}:${timestamp.slice(2)}`
    }
  }
  return timestamp
}

export const startStopTimelineWindow = (timestamp, period) => [
  timestamp - TimelineTimeWindow.BEFORE,
  timestamp + period * Time.HOUR_IN_MILISECONDS
]

export const startStopTimelineHotspotsWindow = (timestamp, period, step) => [
  nearestPastInterval(step, timestamp - step - TimelineTimeWindow.BEFORE),
  nearestNextInterval(
    step,
    timestamp + step + period * Time.HOUR_IN_MILISECONDS
  )
]

export const fromToIntersection = (start, stop, from, to) =>
  start <= to && stop >= from
