import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTextField from '@material-ui/core/TextField'

const mainStyles = (theme) => ({
  textField: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.blue3bis
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.common.grey3
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.grey3
    },
    borderRadius: 2
  },
  cssLabel: {
    color: theme.palette.common.white,
    '&.Mui-focused': {
      color: theme.palette.common.white
    }
  },
  cssInput: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: theme.palette.common.blue3bis,
    backgroundColor: theme.palette.common.blue3bis,
    color: theme.palette.common.white,
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.blue3bis,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.blue3bis,
    },
  },
  cssHelper: {
    marginLeft: 0,
    color: theme.palette.common.white
  },
  cssError: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.common.red
  },
  cssFocused: {
    color: theme.palette.common.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.common.bluewriting
  },
  cssDisabled: {
    borderRadius: 2,
    backgroundColor: theme.palette.common.grey5,
    color: theme.palette.common.grey2
  },
  noLabel: {
    padding: 0,
    height: 32,
    '& input': {
      padding: '0 8px',
      height: 30,
    }
  }
})

const AtfmTextField = ({ classes, ...other }) => {
  const css = [classes.textField]
  if (!other.label) css.push(classes.noLabel)
  return (
    <MuiTextField
      {...other}
      variant='filled'
      className={css.join(' ')}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.focused
        }
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.cssInput,
          focused: classes.cssFocused,
          disabled: classes.cssDisabled,
          error: classes.cssError
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.cssHelper
        }
      }}
    />
  )
}

const PrimaryTextField = withStyles(mainStyles)(AtfmTextField)

const DarkTextField = withStyles((theme) => {
  const { textField, cssInput, cssDisabled, ...other } = mainStyles(theme)
  return {
    textField: {
      ...textField,
      '& .MuiFilledInput-root': {
        backgroundColor: theme.palette.common.blue2
      }
    },
    cssInput: {
      ...cssInput,
      backgroundColor: theme.palette.common.blue2,
      borderColor: theme.palette.common.blue2,
      '&.Mui-focused': {
        backgroundColor: theme.palette.common.blue2,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.blue2,
      },
    },
    cssDisabled: {
      ...cssDisabled,
      backgroundColor: theme.palette.common.blue2,
      color: theme.palette.common.grey2
    },
    noLabel: {},
    ...other
  }
})(AtfmTextField)

export { PrimaryTextField, DarkTextField }

export const DefaultTextField = withStyles((theme) => ({}))(MuiTextField)
