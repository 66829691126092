import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'

export const Button = withStyles((theme) => ({
  contained: {
    boxShadow: theme.shadows[0],
    color: theme.palette.getContrastText(theme.palette.common.blue),
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.bluewirting
    }
  },
  containedSecondary: {
    border: 1,
    borderColor: theme.palette.common.white,
    borderStyle: 'solid',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      backgroundColor: theme.palette.common.blue4
    }
  },
  containedSizeSmall: {
    paddingTop: 5,
    paddingBottom: 5
  },
  outlined: {
    border: 1,
    borderColor: theme.palette.common.blue,
    borderStyle: 'solid',
    color: theme.palette.common.blue,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      backgroundColor: theme.palette.common.blue4
    }
  },
  outlinedSecondary: {
    border: 1,
    borderColor: theme.palette.common.white,
    borderStyle: 'solid',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      backgroundColor: theme.palette.common.blue4
    }
  },
  root: {},
  startIcon: {}
}))(MuiButton)

export const PrimaryButton = withStyles((theme) => ({}))(Button)

export const SecondaryButton = withStyles((theme) => ({
  root: {
    border: 1,
    borderColor: theme.palette.common.white,
    borderStyle: 'solid',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      backgroundColor: theme.palette.common.blue4
    }
  },
  startIcon: {},
  contained: {},
  outlined: {}
}))(Button)

export const AtfmIconButton=withStyles((theme) => {
  const { blue4: backgroundColor } = theme.palette.common;
  return {
    root: {
      border: 'none',
      minWidth: '15px',
      marginLeft: '10px',
    },
    startIcon: {
      marginRight: '-4px',
    },
    contained: {
      '&:hover': {
        backgroundColor,
      },
      '&:focus': {
        backgroundColor,
      },
      '&:active': {
        backgroundColor,
      },
    },
  };
})(SecondaryButton);
