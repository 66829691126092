import React, { useRef, useEffect, useState } from 'react'
import moment from 'moment'

import { ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Schedule } from '@material-ui/icons'
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { timezoneToUTC, utcToTimezone } from '@atfm/utils'

import { UTCUtils, mainStyles, keyboardOnlyStyles, getFirstValue, isValidDateTimeOrNull } from './common'

export const timePickerStyles = (theme) => {
  const {
    blue3bis: backgroundColor,
    white: labelColor,
    bluewriting: focusBorderColor,
  } = theme.palette.common

  return {
    ...mainStyles(theme),
    timePickerPopover: {
      '& .MuiPopover-paper': {
        backgroundColor,
        color: labelColor,
        marginTop: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: focusBorderColor
      },
      '& .MuiPickersClockNumber-clockNumber': {
        color: labelColor
      }
    },
  }
}

const AtfmTimePicker = ({
  value = null,
  label = 'Set time',
  classes,
  noLabel,
  disabled,
  error,
  helperText,
  floorHours,
  onChange,
  placeholder,
  invalidTimeErrorMessage = 'Invalid time',
  ...other
}) => {
  const floorHourIfNeeded = (date) => {
    if (!date || !floorHours) {
      return date
    }

    return moment(date).set({
      minute: 0,
      seconds: 0
    }).toDate()
  }

  const [timeSet, setTime] = useState(value ? floorHourIfNeeded(utcToTimezone(getFirstValue(value))) : null)

  const handleTimeChange = (time) => {
    if (!isValidDateTimeOrNull(time)) {
      setValid(false)
      if (onChange) {
        onChange(time)
      }
      return
    }

    setValid(true)
    if (time) {
      const timezoneTime = floorHourIfNeeded(time)
      setTime(timezoneTime)
      if (onChange) {
        const utcTime = timezoneToUTC(timezoneTime)
        onChange(utcTime)
      }
    } else {
      setTime(time)
      if (onChange) {
        onChange(time)
      }
    }
  }

  const picker = useRef(null)
  const [valid, setValid] = useState(true);
  const [focused, setFocused] = useState(false)

  const config = { ...other }

  useEffect(() => {
    const timezoneTime = value ? floorHourIfNeeded(utcToTimezone(getFirstValue(value))) : null
    setTime(timezoneTime)
  }, [value])

  if (floorHours) {
    config.views = ['hours']
  }

  const css = [classes.dateAndTimePicker]
  if (noLabel) css.push(classes.noLabel)
  if (disabled) css.push(classes.disabled)
  if (error || !valid) css.push(classes.error)
  if (focused) css.push(classes.focused)

  return (
    <MuiPickersUtilsProvider utils={UTCUtils}>
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <div ref={picker}> {/* needed for the ClickAwayListener to work */}
          <KeyboardTimePicker
            className={css.join(' ')}
            autoOk
            disableToolbar
            ampm={false}
            variant='inline'
            error={error || !valid}
            helperText={helperText || (!valid && invalidTimeErrorMessage)}
            disabled={disabled}
            label={noLabel ? null : label}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input
              },
              placeholder,
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false)
            }}
            keyboardIcon={<Schedule />}
            InputAdornmentProps={{
              classes: {
                root: classes.inputAdornment
              },
              onClick: () => setFocused(!focused)
            }}
            PopoverProps={{
              classes: {
                root: classes.timePickerPopover
              },
              TransitionProps: {
                onExiting: () => setFocused(false),
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.helper
              }
            }}
            value={timeSet}
            onChange={handleTimeChange}
            {...config}
          />
        </div>
      </ClickAwayListener>
    </MuiPickersUtilsProvider>
  )
}

const secondaryStyles = theme => ({
  dateAndTimePicker: {
    backgroundColor: theme.palette.common.blue2
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  noLabel: {},
  helper: {},
  input: {}
})

const PrimaryTimePicker = withStyles(timePickerStyles)(AtfmTimePicker)
const SecondaryTimePicker = withStyles(secondaryStyles)(PrimaryTimePicker)

const PrimaryKeyboardOnlyTimePicker = withStyles(keyboardOnlyStyles)(props => <PrimaryTimePicker placeholder="HH:mm" {...props} label={null} keyboardIcon={null} />)
const SecondaryKeyboardOnlyTimePicker = withStyles(secondaryStyles)(PrimaryKeyboardOnlyTimePicker)

export { PrimaryTimePicker, SecondaryTimePicker, PrimaryKeyboardOnlyTimePicker, SecondaryKeyboardOnlyTimePicker }
