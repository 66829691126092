export class StorageHelper {
  static getStorageInitialState() {
    return {
      error: {},
      isLoggedIn: !!this.retrieveItem('ad_access_token')
    }
  }

  static retrieveItem(key) {
    const itemValue = localStorage.getItem(key)
    if (key === 'notes_read' || key === 'notes_unread') {
      return itemValue !== null && itemValue !== undefined
        ? JSON.parse(itemValue)
        : []
    }
    return itemValue !== null && itemValue !== undefined
      ? JSON.parse(itemValue)
      : null
  }

  static storeItem(key, item) {
    if (item === undefined) {
      this.clearItem(key)
      return
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  static clearItem(item) {
    localStorage.removeItem(item)
  }
}
