import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'

export const PrimarySnackBar = withStyles((theme) => ({}))(Snackbar)

export const PrimaryAlert = withStyles((theme) => ({
  standardSuccess: {
    borderRadius: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.greendarker,
    border: `solid 1px ${theme.palette.common.greendarker}`,
    '& .MuiAlert-icon': {
      color: theme.palette.common.white,
    }
  },
  standardInfo: {
    borderRadius: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue,
    border: `solid 1px ${theme.palette.common.blue}`,
    '& .MuiAlert-icon': {
      color: theme.palette.common.white,
    }
  },
  standardWarning: {
    borderRadius: 2,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.yellow,
    border: `solid 1px ${theme.palette.common.yellow}`,
    '& .MuiAlert-icon': {
      color: theme.palette.common.black,
    }
  },
  standardError: {
    borderRadius: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.reddarker,
    border: `solid 1px ${theme.palette.common.reddarker}`,
    '& .MuiAlert-icon': {
      color: theme.palette.common.white,
    }
  },
  message: {
    padding: `10px 0`,
  },
}))(Alert)
