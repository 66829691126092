import React from 'react'
import { withStyles } from '@material-ui/core';

const errorMessageStyles = ({ palette, typography }) => ({
  root: {
    ...typography.caption,
    color: palette.error.main,
    height: 20,
    marginTop: 3,
    marginBottom: 0
  },
  hidden: {
    visibility: 'hidden',
  },
});

export const ErrorMessage = withStyles(errorMessageStyles)(({ visible, classes, children, className }) => {
  const css = [classes.root, className];
  if (!visible) css.push(classes.hidden);
  return (
    <p className={css.join(' ')}>{visible && children}</p>
  );
});
