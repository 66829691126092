import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const smallTooltipStyles = (theme) => {
  const {
    blue5: backgroundColor,
  } = theme.palette.common
  return {
    tooltip: {
      borderRadius: 2,
      backgroundColor,
      fontSize: 14,
    },
    arrow: {
      color: backgroundColor,
    }
  }
}

const ATFMBaseSmallTooltip = ({
  toolKey,
  handleTooltipOpen,
  inputComponent,
  ...other
}) => (
  <Tooltip
    arrow
    title={toolKey}
    disableHoverListener={handleTooltipOpen}
    disableTouchListener={true}
    disableFocusListener={true}
    {...other}
  >
    {inputComponent}
  </Tooltip>
)

export const ATFMSmallTooltip = withStyles(smallTooltipStyles)(ATFMBaseSmallTooltip)

ATFMSmallTooltip.propTypes = {
  toolKey: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  handleTooltipOpen: PropTypes.bool.isRequired,
  inputComponent: PropTypes.shape().isRequired,
};

const largeTooltipStyles = (theme) => {
  const {
    blue5: backgroundColor,
  } = theme.palette.common
  return {
    tooltip: {
      flexGrow: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',  
      gap: 8,
      padding: 16,
      borderRadius: 2,
      fontSize: 12,
      backgroundColor,
      color: theme.palette.common.white,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.1,
      textAlign: 'left',
    },
    arrow: {
      color: backgroundColor,
    }
  }
}

const ATFMBaseLargeTooltip = ({
  toolTitle,
  description,
  handleTooltipOpen,
  inputComponent,
  ...other
}) => (
  <Tooltip
    arrow
    title={
      <React.Fragment>
        <div style={{ fontSize: 14 }}>
          {toolTitle}
        </div>
        {description}
      </React.Fragment>
    }
    disableHoverListener={handleTooltipOpen}
    disableTouchListener={true}
    disableFocusListener={true}
    {...other}
  >
    {inputComponent}
  </Tooltip>
)

export const ATFMLargeTooltip = withStyles(largeTooltipStyles)(ATFMBaseLargeTooltip)

ATFMLargeTooltip.propTypes = {
  toolTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  handleTooltipOpen: PropTypes.bool.isRequired,
  inputComponent: PropTypes.shape().isRequired,
};

export const ATFMErrorTooltip = withStyles((theme) => {
  const { tooltip, arrow, ...other } =  smallTooltipStyles(theme);
  const {
    reddarker: backgroundColor,
  } = theme.palette.common
  return {
    ...other,
    tooltip: {
      ...tooltip,
      backgroundColor,
    },
    arrow: {
      ...arrow,
      color: backgroundColor,
    }
  }
})(ATFMBaseSmallTooltip)

ATFMErrorTooltip.propTypes = {
  toolKey: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  handleTooltipOpen: PropTypes.bool.isRequired,
  inputComponent: PropTypes.shape().isRequired,
};