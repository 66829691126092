import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles'

import { PrimaryDatePicker, SecondaryDatePicker } from './datepicker'
import { PrimaryKeyboardOnlyTimePicker, SecondaryKeyboardOnlyTimePicker } from './timepicker'
import { ErrorMessage } from '../errormessage'
import { isValidDateTimeOrNull } from './common'

const joinDateAndTime = (date, time) => moment.utc(date)
  .set('hours', time.getUTCHours())
  .set('minutes', time.getUTCMinutes())
  .set('seconds', time.getUTCSeconds())
  .set('milliseconds', time.getUTCMilliseconds())
  .toDate();

const dateTimePickerStyles = (theme) => ({
  container: {
    minWidth: 193,
    display: 'flex',
    flexDirection: 'column',
  },
  noLabel: {
    minWidth: 181,
    '& .MuiInput-input': {
      width: 73,
    }
  },
  pickersContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  errorMessage: {},
  helper: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    height: 20,
    marginTop: 3,
    marginBottom: 0
  },
  datePickerContainer: {},
  timePickerContainer: {
    maxWidth: 50
  },
})

const innerComponentStyles = makeStyles(() => createStyles({
  timePicker: {
    height: 36,
    justifyContent: 'center',
    maxHeight: 'unset',
  },
  noLabel: {
    height: 32,
  },
  datePickerInput: {
    '& > .MuiInput-input': {
      width: 81,
    },
  },
  timePickerInput: {
    '& > .MuiInput-input': {
      textAlign: 'center',
      maxWidth: 36,
    }
  },
  helper: {
    display: 'none'
  },
}))

const AtfmDateTimePicker = (DatePicker, TimePicker) => ({
  label,
  classes,
  disabled,
  value,
  onChange,
  error,
  helperText,
  floorHours,
  disableFuture,
  disablePast,
  DatePickerProps = {},
  TimePickerProps = {},
  invalidDateErrorMessage = 'Invalid date',
  invalidTimeErrorMessage = 'Invalid time'
}) => {
  const {
    container,
    noLabel: noLabelStyles,
    pickersContainer,
    errorMessage,
    helper,
    datePickerContainer,
    timePickerContainer
  } = classes

  const {
    timePicker,
    datePickerInput,
    timePickerInput,
    ...otherClasses
  } = innerComponentStyles()

  const [date, setDate] = useState(value);
  const [time, setTime] = useState(value);

  const [isDateChanging, setIsDateChanging] = useState(false);
  const [isTimeChanging, setIsTimeChanging] = useState(false);

  const [validDate, setValidDate] = useState(true);
  const [validTime, setValidTime] = useState(true);

  const handleDateChange = (newDate) => {
    setIsDateChanging(true)
    setDate(newDate)

    if (isValidDateTimeOrNull(newDate)) {
      setValidDate(true)
      const newValue = (newDate && time) ? joinDateAndTime(newDate, time) : null

      if (onChange) {
        onChange(newValue)
      }
    } else {
      setValidDate(false)
    }
  }

  const handleTimeChange = (newTime) => {
    setIsTimeChanging(true)
    setTime(newTime)

    if (isValidDateTimeOrNull(newTime)) {
      setValidTime(true)
      const newValue = (date && newTime) ? joinDateAndTime(date, newTime) : null

      if (onChange) {
        onChange(newValue)
      }
    } else {
      setValidTime(false)
    }
  }

  useEffect(() => {
    // resets state variables after changing fields
    setIsDateChanging(false)
    setIsTimeChanging(false)
  }, [date, time]);

  useEffect(() => {
    if (!isDateChanging && !isTimeChanging) {
      // change came from outside of the component, update both
      setDate(value)
      setTime(value)
      setValidDate(true)
      setValidTime(true)
    } else if (isDateChanging) {
      // change came from internal date picker
      setDate(value)
    } else if (isTimeChanging) {
      // change came from internal time picker
      setTime(value)
    }
  }, [value])

  const noLabel = !label || label.trim() === ''

  const renderHelperTextOrErrorMessage = () => {
    if (error) {
      return <ErrorMessage visible className={errorMessage}>{helperText || ''}</ErrorMessage>
    }

    if (!validDate) {
      return <ErrorMessage visible className={errorMessage}>{helperText || invalidDateErrorMessage}</ErrorMessage>
    }

    if (!validTime) {
      return <ErrorMessage visible className={errorMessage}>{helperText || invalidTimeErrorMessage}</ErrorMessage>
    }

    return <p className={helper}>{helperText || ''}</p>
  }

  return (
    <div className={`${container} ${noLabel ? noLabelStyles : ''}`}>
      <div className={pickersContainer}>
        <div className={datePickerContainer}>
          <DatePicker
            {...DatePickerProps}
            disableFuture={disableFuture}
            disablePast={disablePast}
            noLabel={noLabel}
            classes={{
              ...otherClasses,
              input: datePickerInput
            }}
            label={label}
            value={date}
            onChange={handleDateChange}
            error={error}
            disabled={disabled}
          />
        </div>
        <div className={timePickerContainer}>
          <TimePicker
            placeholder=""
            {...TimePickerProps}
            disableFuture={disableFuture}
            disablePast={disablePast}
            noLabel={noLabel}
            floorHours={floorHours}
            classes={{
              ...otherClasses,
              dateAndTimePicker: timePicker,
              input: timePickerInput
            }}
            value={time}
            onChange={handleTimeChange}
            error={error}
            disabled={disabled}
          />
        </div>
      </div>
      {renderHelperTextOrErrorMessage()}
    </div>
  )
}

const PrimaryDateTimePicker = withStyles(dateTimePickerStyles)(AtfmDateTimePicker(PrimaryDatePicker, PrimaryKeyboardOnlyTimePicker))
const SecondaryDateTimePicker = withStyles(dateTimePickerStyles)(AtfmDateTimePicker(SecondaryDatePicker, SecondaryKeyboardOnlyTimePicker))

export { PrimaryDateTimePicker, SecondaryDateTimePicker }
