/* eslint-disable no-console */

export class ErrorHandler {
  // eslint-disable-next-line no-unused-vars
  static logError(error) {
    console.log(error)
  }

  // eslint-disable-next-line no-unused-vars
  static logReactError(error) {
    console.log(error)
  }
}
