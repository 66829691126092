import React from 'react'
import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core'

const AtfmDarkTheme = createTheme({
  typography: {
    htmlFontSize: 14,
    button: {
      textTransform: 'none'
    },
  },
  palette: {
    common: {
      black: '#000000',
      blue2: '#1d212b',
      blue3: '#242936',
      blue3bis: '#2e3546',
      blue4: '#3b4459',
      blue5: '#566180',
      blue6: '#12131c',
      bluewriting: '#4283ff',
      blue: '#2566e2',
      bluedarker: '#1a4ba9',
      green: '#00bfa5',
      greendarker: '#0d8274',
      grey1: '#313232',
      grey2: '#636465',
      grey3: '#949597',
      grey4: '#c6c7c9',
      grey5: '#dee0e1',
      grey6: '#eaecee',
      greyDisable: '#989aa0',
      orange: '#fa6400',
      red2: '#ff1744',
      red: '#ff3455',
      reddarker: '#c3253f',
      white: '#ffffff',
      yellow2: '#ffd625',
      yellow: '#ffdd00',
      yellowdarker: '#edbd00'
    },
    background: {
      default: '#242936'
    },
    primary: {
      main: '#2566e2'
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0)'
    },
    action: {
      disabled: '#989aa0',
      disabledBackground: '#171a22',
    },
    type: 'dark'
  },
  shape: {
    borderRadius: 2
  },
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply by default
      variant: 'contained',
      size: 'small'
    }
  }
})

export const AtfmTheme = ({ children }) => (
  <ThemeProvider theme={AtfmDarkTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

export { useTheme, withTheme } from '@material-ui/core'