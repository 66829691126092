export const TimeTypes = Object.freeze({
  RUNNING: 'RUNNING',
  FIXED: 'FIXED'
})

export const Time = Object.freeze({
  MINUTE_IN_MILISECONDS: 60000,
  HOUR_IN_MILISECONDS: 3600000
})

export const TimelineTimeWindow = Object.freeze({
  BEFORE: 15 * Time.MINUTE_IN_MILISECONDS
})

export const TimerIntervals = Object.freeze({
  TIME: 10000,
  VERSION: 5 * Time.MINUTE_IN_MILISECONDS
})

export const CommonValidationMessages = Object.freeze({
  FL_NUMBER: 'Flight levels must be integers',
  FL_POSITIVE: 'Flight levels must be positive',
  FL_MAX_NUMBERS: 'Flight levels must have no more than 4 numbers',
  FL_MAX_LOWER_THAN_MIN: "FL Max can't be lower than FL Min",
  DATE_INVALID_FORMAT: 'Date must be in DD/MM/YYYY format',
  TIME_INVALID_FORMAT: 'Time must be in HH:mm format',
  FIELD_MANDATORY: 'Field is mandatory',
  TO_SMALLER_FROM_ERROR: 'TO value must not be lower or equal than the FROM'
})

export const GdpValidationMessages = Object.freeze({
  RATE_INVALID: 'Rate must be an integer greater or equal than 0',
  MIT_INVALID: 'Miles In Trails must be an integer greater than 1',
  TIME_DIFF_ERROR_TOO_SHORT:
    'Time difference between FROM and TO must be more than 20 minutes',
  TIME_DIFF_ERROR_TOO_LONG:
    'Time difference between FROM and TO must be less than 6 hours',
  TFV_INVALID: 'TFV must be valid'
})
